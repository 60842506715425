import { adminHomeFields } from "../constants/formFields";
import { useState, useEffect, useCallback } from 'react';
import FormAction from "./FormAction";
import Input from "./Input";

// Define the initial state for form fields
const fields = adminHomeFields;
let fieldsState = {};
fields.forEach(field => fieldsState[field.id] = '');

export default function AdminHome() {
    // State to store form field values
    const [loginState, setLoginState] = useState(fieldsState);
    // State to control flash messages
    const [flashMessage, setFlashMessage] = useState({
        text: "",
        success: false,
        failure: false,
    });
    const [availableQuestionCount, setAvailableQuestionCount] = useState("");
    const [divNumber, setDivNumber] = useState(1);
    const [menuNumber, setMenuNumber] = useState(1);

    const [activeUsers, setActiveUsers] = useState([]);

    // Function to fetch available question count
    const fetchAvailableQuestionCount = useCallback(async () => {
        try {
            const response = await fetch("/available_question_count");
            if (response.status === 200) {
                const data = await response.json();
                setAvailableQuestionCount(data.available_question_count);
            } else {
                console.error("Error: Failed to fetch available_question_count");
                handleFlashMessage("Failed to fetch available_question_count", false);
            }
        } catch (error) {
            console.error("Error: An error occurred while fetching available_question_count", error);
            handleFlashMessage("Error: " + error, false);
        }
    }, []);

    useEffect(() => {
        fetchAvailableQuestionCount();
    }, [fetchAvailableQuestionCount]);

    // Handle input field changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        const min = 1;
        const max = availableQuestionCount;

        if (value < min) {
            newValue = min;
        } else if (value > max) {
            newValue = max;
        }

        setLoginState({ ...loginState, [id]: newValue });
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Parse the question number input
        const quesNumber = parseInt((document.getElementById("quesNumber")).value);

        try {
            // Send a POST request to the `/submit_number` route
            const response = await fetch("/submit_number", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ numberInput: quesNumber }),
            });

            if (response.status === 200) {
                handleFlashMessage("Selected questions successfully", true);

                // Reset the form
                resetForm();
                fetchAvailableQuestionCount();
            } else if (response.status === 400) {
                handleFlashMessage("Please enter a valid number", false);
            } else {
                console.error("Error: Failed to send JSON response to API app");
                handleFlashMessage("Failed to select questions. Please select a number less than questions remaining", false);
            }
        } catch (error) {
            console.error("Error: An error occurred", error);
            handleFlashMessage("Error: " + error, false);
        }
    };

    const getActiveUsers = async () => {
        fetch("/active_users", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                if (response.ok) {
                    const data = await response.json();
                    setActiveUsers(data.active_users);
                }
            })
            .catch((error) => {
                console.error("An error ocurred:", error);
                handleFlashMessage("Error:" + error, false);
            })
    };

    // Function to handle user registration button click
    const resetForm = () => {
        const loginState = {};
        fields.forEach(field => loginState[field.id] = '');
        setLoginState(loginState);
    };

    // Function to handle user registration button click
    const handleUserRegButton = () => {
        window.location.href = '/userregistration';
    }

    const handleUserManageButton = () => {
        setMenuNumber(2);
        setDivNumber(0);
    }

    const handleActiveUsersButton = () => {
        setDivNumber(2);
        getActiveUsers();
    }

    const handleBackButton = () => {
        setMenuNumber(1);
        setDivNumber(1);
    }

    // Function to handle dashboard button click
    const handleDashboardButton = () => {
        window.location.href = '/dashboard';
    }

    // Function to handle flash messages
    const handleFlashMessage = (text, success) => {
        setFlashMessage({ text, success, failure: !success });
        setTimeout(() => setFlashMessage({ text: "", success: false, failure: false }), 3000);
    };

    // Function to handle logout button click
    const handleLogoutButton = () => {
        // Assuming you want to use a GET request to trigger the logout on the server
        fetch('/logout', {
            method: 'GET',
            credentials: 'same-origin', // Include this if you need to send cookies
        })
            .then(response => {
                if (response.status === 200) {
                    // Redirect to the root URL after successful logout
                    window.location.href = '/';
                } else {
                    // Handle the case where logout was not successful (e.g., display an error message)
                    console.error('Logout failed');
                    handleFlashMessage("Logout failed", false);
                }
            })
            .catch(error => {
                console.error('An error occurred during logout:', error);
                handleFlashMessage("Error: " + error, false);
            });
    }

    // Function to reset question flags
    const handleResetButton = async () => {
        try {
            // Send a POST request to the `/api/reset_question_flags` endpoint
            const response = await fetch("/reset_question_flags", {
                method: 'POST',
            });

            if (response.status === 200) {
                handleFlashMessage("Question flags reset successfully", true);
                fetchAvailableQuestionCount();
            } else {
                console.error("Error: Failed to reset question flags");
                handleFlashMessage("Failed to reset question flags", false);
            }
        } catch (error) {
            console.error("Error: An error occurred while resetting question flags", error);
            handleFlashMessage("An error occurred while resetting question flags", false);
        }
    };


    return (
        <div>

            {/* Displaying success flash message */}
            {flashMessage.success && (
                <div id="successFlashMsg">{flashMessage.text}</div>
            )}

            {/* Displaying failure flash message */}
            {flashMessage.failure && (
                <div id="failFlashMsg">{flashMessage.text}</div>
            )}

            {/* Buttons for user registration and dashboard */}
            {menuNumber === 1 && (
                <div className="flex justify-center mt-4">
                    <button className="group relative flex py-2.5 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={handleUserManageButton}>
                        User Management
                    </button>
                    <button className="group relative flex py-2.5 px-9 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ml-10" onClick={handleDashboardButton}>
                        Dashboard
                    </button>
                </div>
            )}

            {menuNumber === 2 && (
                <div className="flex justify-center mt-4">
                    <button className="group relative flex py-2.5 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={handleUserRegButton}>
                        User Registration
                    </button>
                    <button className="group relative flex py-2.5 px-9 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ml-10" onClick={handleActiveUsersButton}>
                        Active Users
                    </button>
                </div>
            )}

            <br />

            {divNumber === 1 && (
                <div>
                    {/* Form for submitting the number of questions */}
                    <form className="mt-4 space-y-6" onSubmit={handleSubmit} id="inputForm">
                        <div className="-space-y-px">
                            {/* Render input fields based on the 'fields' configuration */}
                            {fields.map(field => (
                                <Input
                                    key={field.id}
                                    handleChange={handleChange}
                                    value={loginState[field.id]}
                                    labelText={field.labelText}
                                    labelFor={field.labelFor}
                                    id={field.id}
                                    name={field.name}
                                    type={field.type}
                                    isRequired={field.isRequired}
                                    placeholder={field.placeholder}
                                    maxLength={field.maxLength}
                                />
                            ))}
                        </div>

                        {/* Form submission action button */}
                        <FormAction handleSubmit={handleSubmit} text="Submit" />
                    </form>

                    {/* Display available question count and reset flags button */}
                    <div className="mt-2 text-center text-sm text-gray-600 mt-5">
                        Number of questions remaining: <span>{availableQuestionCount}</span> <br />
                        <button onClick={handleResetButton} className="font-medium text-purple-600 hover:text-purple-500 mt-2 text-center text-sm mt-5">
                            RESET Flags
                        </button>
                    </div>
                </div>
            )}

            {divNumber === 2 && (
                <div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead style={{ position: 'sticky', top: 0, background: 'rgba(255, 255, 255, 1)' }}>
                                <tr>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>S No.</th>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>Username</th>
                                </tr>
                            </thead>
                            {/* Table body with active users data */}
                            <tbody>
                                {activeUsers.map((username, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{username}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="text-right mt-5">
                        <button onClick={handleBackButton} className="text-sm text-gray-600 font-medium text-purple-600 hover:text-purple-500">
                            Back
                        </button>
                    </div>
                </div>
            )}

            <br />

            {/* Logout button */}
            <div>
                <center>
                    <button className="group relative flex py-2 px-9 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-4" onClick={handleLogoutButton}>
                        Logout
                    </button>
                </center>
            </div>

        </div>
    )
}