import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

export default function Dashboard() {
    // State variables to manage the user's selections and flash messages
    const [loginState, setLoginState] = useState({
        user: '',
        session: '',
    });
    // State to control flash messages
    const [flashMessage, setFlashMessage] = useState({
        text: "",
        success: false,
        failure: false,
    });

    // State variables to store data fetched from the server
    const [users, setUsers] = useState([]); // To store usernames
    const [sessions, setSessions] = useState([]); // Store distinct session IDs
    const [transactions, setTransactions] = useState([]); // Store transaction data

    // State variables to control the visibility of transaction table and chart
    const [showTransactions, setShowTransactions] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [chartDetails, setChartDetails] = useState({});

    // Fetch the list of users when the component mounts
    useEffect(() => {
        fetch("/initialdashboard") // Replace with your actual API endpoint
            .then((response) => response.json())
            .then((data) => {
                setUsers(data.usernames);
            })
            .catch((error) => console.error("Error fetching users:", error));
    }, []);

    // Fetch sessions data when loginState.user changes
    useEffect(() => {
        if (loginState.user) {
            fetch("/get_sessions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: loginState.user }), // Pass the selected username in the body
            })
                .then((response) => response.json())
                .then((data) => {
                    // Assuming data.sessions is an array of session IDs
                    setSessions(data);
                })
                .catch((error) => console.error("Error fetching sessions:", error));
        }
    }, [loginState.user]);

    // Function to handle flash messages
    const handleFlashMessage = (text, success) => {
        setFlashMessage({ text, success, failure: !success });
        setTimeout(() => setFlashMessage({ text: "", success: false, failure: false }), 2000);
    };

    // Handle input changes
    const handleChange = (e) => {
        setLoginState({ ...loginState, [e.target.name]: e.target.value });
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowChart(false);
        setShowTransactions(true);
        getTransactions();
    }

    // Fetch transaction data from the server
    const getTransactions = async () => {
        const { user, session } = loginState;

        if (!user || !session) {
            return;
        }

        try {
            const requestData = {
                user: user,
                session: session,
            };

            const response = await fetch('/dashboardtransactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                console.error("Error fetching transaction data");
                handleFlashMessage("Error fetching transaction data. Please try again.", false);
                return;
            }

            const transactionsData = await response.json();
            setTransactions(transactionsData.transactions_html);
        } catch (error) {
            console.error("An error occurred while fetching transaction data:", error);
            handleFlashMessage("Error: " + error, false);
        }
    }

    // Function to render checkmark (✓) for true and cross mark (✖) for false
    const getFlagSymbol = (flag) => {
        // "&#9989;" for true and "&#10062;" for false
        return flag ? "\u2714" : "\u2716";
    }

    // Handle fetching and displaying the chart
    const handleChartButton = async () => {
        try {
            // Get the username from the loginState.user object
            const username = loginState.user;
            const requestData = {
                username: username,
            };

            // Define the fetch options including the request method, headers, and body
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            };

            const response = await fetch('/chart', requestOptions);

            if (response.status === 200) {
                const chartData = await response.json();
                setChartDetails(chartData);
            } else {
                console.error("Failed to fetch session details");
                handleFlashMessage("Failed to fetch session details", false);
            }
        } catch (error) {
            console.error("Error:", error);
            handleFlashMessage("Error: " + error, false);
        }
        setShowTransactions(false);
        setShowChart(true);
    };

    // CSS class for buttons
    const buttonClass = "group relative flex items-center justify-center py-2.5 px-9 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500";


    return (
        <div>

            {/* Displaying failure flash message */}
            {flashMessage.failure && (
                <div id="failFlashMsg">
                    {flashMessage.text}
                </div>
            )}

            {/* Form for selecting a user and a session */}
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div className="-space-y-px">
                    <div className="mb-4">
                        <select
                            name="user"
                            value={loginState.user}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded-md w-full"
                            required>
                            <option value="">Select User</option>
                            {users.map((data) => (
                                <option key={data} value={data}>
                                    {data}
                                </option>
                            ))}
                        </select>
                    </div>

                    {loginState.user && (
                        <div className="mb-4">
                            <select
                                name="session"
                                value={loginState.session}
                                onChange={handleChange}
                                className="mt-1 p-2 border rounded-md w-full"
                                required>
                                <option value="">Select Session</option>
                                {sessions.map((session) => (
                                    <option key={session} value={session}>
                                        {session}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </form>

            {/* Buttons for displaying transactions and the chart */}
            {loginState.user && (
                <div className="flex justify-between items-center mt-6">
                    <button
                        onClick={handleSubmit}
                        className={buttonClass}
                        style={{ width: '200px', height: '40px' }}>
                        Transactions
                    </button>
                    <button
                        onClick={handleChartButton}
                        className={buttonClass}
                        style={{ width: '200px', height: '40px' }}>
                        View Chart
                    </button>
                </div>
            )}

            {showTransactions && (
                <div>
                    {/* Header for the Transactions table */}
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        Transactions
                    </h2>

                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead style={{ position: 'sticky', top: 0, background: 'rgba(255, 255, 255, 1)' }}>
                                <tr>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>Question ID</th>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>Video Flag</th>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>LLM Flag</th>
                                    <th style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>Result</th>
                                </tr>
                            </thead>
                            {/* Table body with transaction data */}
                            <tbody>
                                {transactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{transaction.questionId}</td>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{getFlagSymbol(transaction.videoFlag)}</td>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{getFlagSymbol(transaction.llmFlag)}</td>
                                        <td style={{ border: '1.5px solid #ddd', textAlign: 'center' }}>{transaction.result}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {showChart && (
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {/* Display an AreaChart using Recharts library */}
                    <AreaChart
                        width={500}
                        height={400}
                        data={chartDetails}
                        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis />
                        <YAxis dataKey="result" />
                        <Tooltip />
                        <Area type="monotone" dataKey="result" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                </div>
            )}

        </div>
    )
}